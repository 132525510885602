"use client";

import classNames from "classnames/bind";
import { CosmosButton } from "@cosmos/web/react";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { Kicker } from "../../kicker/kicker";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import styles from "./organics-teaser-card.module.css";
// import { DietaryMarks } from "../../dietary-marks/dietary-marks";

const cx = classNames.bind(styles);

export const OrganicsTeaserCard = ({
  name,
  description,
  linkText,
  linkUrl,
  backgroundColor,
  backgroundImage,
  logoHeading,
  image,
}: {
  name: string | null;
  description: string | null;
  linkText: string | null;
  linkUrl: string | null;
  backgroundColor: string | null;
  backgroundImage: { description: string | null; url: string | null } | null;
  logoHeading: { description: string | null; url: string | null } | null;
  image: { description: string | null; url: string | null } | null;
}) => {
  const imageLoading = useImageLoading();

  return (
    <div
      className={cx("container")}
      style={{
        "--card-bg-color": backgroundColor ?? undefined,
      }}
    >
      <div className={cx("inner")}>
        <div
          className={cx(`card`, {
            "card--bg-color": backgroundColor,
          })}
        >
          <div className={cx("text-content")}>
            {logoHeading?.url && (
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  logoHeading.url,
                )}
                sizes="(min-width: 650px) 225px, 177px" // Manually kept in-sync with `.logo`
                className={cx("logo")}
                alt={logoHeading.description || "Organics logo"}
              />
            )}
            <div>
              {name && (
                <span className={cx("kicker-wrapper")}>
                  <Kicker kind="bare" text={name} />
                </span>
              )}
              <p className={cx("text")}>{description}</p>
              {linkUrl && linkText && (
                <div className={cx("button-wrapper")}>
                  <CosmosButton
                    href={linkUrl ?? undefined}
                    kind="tertiary"
                    size="large"
                    className={cx("button")}
                  >
                    {linkText}
                  </CosmosButton>
                </div>
              )}
            </div>
          </div>
          {image?.url && (
            <div className={cx("image-wrapper")}>
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(image.url)}
                sizes="(min-width: 1000px) 581px, (min-width:500px): 570px, 95vw" // Manually kept in sync with `.image` and `.card`
                className={cx("image")}
                alt={image.description || "Organics new range"}
              />
              {/* Vegetarian mark currently not implimented */}
              {/* <DietaryMarks
                kind="vegetarian"
                className={cx("dietary-mark-vegetarian")}
              /> */}
            </div>
          )}
          {backgroundImage?.url && (
            <div className={cx("bg-image-wrapper")}>
              <img
                loading={imageLoading}
                srcSet={responsiveImageHelper.createContentfulSrcSet(
                  backgroundImage.url,
                )}
                sizes="(min-width: 1000px) 978px, (min-width: 500px) 600px, 100vw" // Manually kept in sync with `.bg-image-wrapper` and `.card`
                className={cx("bg-image")}
                alt={backgroundImage.description || "Organics new range"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
